#modalDialog {
  position: absolute;
  width: 20rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
}

#modalDialog button {
  float: right;
}
