:root {
  --btc-orange: #f2a900;
  --btc-orange-middle: #f2a900c0;
  --btc-orange-soft: #f2a90070;
  --toastify-color-progress-light: var(--btc-orange) !important;
  --toastify-color-progress-dark: var(--btc-orange) !important;
}

button {
  background: none !important;
  border: none;
  padding: 1rem !important;
  font-family: arial, sans-serif;
  color: var(--btc-orange);
  cursor: pointer;
  font-weight: bold;
}

button:hover {
  text-decoration: underline;
}

button.disabled {
  color: gray;
  cursor: auto;
}

button.disabled:hover {
  text-decoration: none;
}
