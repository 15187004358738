.currency-drop-card {
  min-width: 17.6rem;
  border: 0.05rem solid var(--btc-orange-middle);
  border-image: linear-gradient(0, transparent, var(--btc-orange-middle)) 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0.5rem;
  float: left;
  padding: 0.8rem;
  -webkit-transition: 0.3s; /* For Safari 3.0 to 6.0 */
  transition: 0.3s; /* For modern browsers */
}

.currency-drop-card:hover {
  cursor: pointer;
  background: var(--btc-orange-light);
  border: 0.05rem solid var(--btc-orange-middle);
}

.currency-drop-card .image {
  width: 2.2rem;
  height: 2.2rem;
  float: right;
}

.currency-drop-card .badge {
  border-radius: 0.2rem;
  border-bottom: 0.05rem solid var(--btc-orange-middle);
  border-right: 0.05rem solid var(--btc-orange-middle);
  box-shadow: rgba(99, 99, 99, 0.15) 0px 2px 8px 0px;
  padding: 0 0.4rem 0.1rem 0.4rem;
  margin-right: 0.4rem;
  display: inline-block;
  float: left;
  font-weight: bold;
}

.currency-drop-card .name {
  padding-bottom: 0.35rem;
}

.currency-drop-card .delimiter {
  margin: 0.6rem 0 0.6rem 0;
  background-color: var(--btc-orange-soft);
  height: 0.05rem;
  width: 100%;
}

@media (max-width: 65.5rem) {
  .currency-drop-card {
    min-width: calc(100% / 2 - 2.73rem);
  }
}

@media (max-width: 44rem) {
  .currency-drop-card {
    min-width: calc(100% - 2.73rem);
  }
}
