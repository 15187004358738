.header {
  width: 100%;
  background-color: white;
  border-top: 0.3rem solid var(--btc-orange);
  border-bottom: 0.3rem solid var(--btc-orange);
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 3px,
    rgba(0, 0, 0, 0.2) 0px -3px 3px;
  position: relative;
  z-index: 1;
}

.header .content {
  max-width: calc(994px - 2rem);
  padding: 0 1rem;
  margin: 1rem auto;
  display: block;
  /* overflow: auto; */
  height: 3rem;
}

.header .title {
  display: block;
  float: left;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.9rem;
}

.header .logo {
  display: block;
  height: 3rem;
  width: 3rem;
  float: right;
  line-height: 3rem;
}
