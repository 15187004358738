#root {
  background-color: whitesmoke;
}

.App {
  text-align: center;
}

.App-link {
  color: var(--btc-orange);
}

.content-lines {
  margin: 0 1rem;
}

.content-lines .content-line {
  display: block;
  float: left;
}
