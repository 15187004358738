.footer {
  width: 100%;
  background-color: white;
  border-top: 0.2rem solid var(--btc-orange);
  color: rgb(93, 93, 93);
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.2);
}

.footer .content {
  max-width: calc(994px - 2rem);
  padding: 0 1rem;
  margin: 1rem auto;
  display: block;
  overflow: hidden;
}

.footer .donate {
  margin-right: 1rem;
  display: block;
  float: left;
  text-decoration: underline;
  text-decoration-color: var(--btc-orange-soft);
}

.footer .contact {
  display: block;
  float: right;
}

.footer .contact .label {
  text-decoration: underline;
  text-decoration-color: var(--btc-orange-soft);
}

/* TODO: add float to left of contact if resolution below something */

.footer .contact .e-mail {
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
}

.footer .contact .e-mail:hover {
  color: black;
}

.footer .address {
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
  float: left;
}

.footer .address:hover {
  color: black;
}

@media screen and (orientation: portrait) {
  .footer .contact {
    float: left;
  }
}
