.content-area {
  width: 100%;
}

.content-area .content {
  max-width: 994px;
  padding: 0.5rem 0;
  margin: 0 auto;
  display: block;
  overflow: auto;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  background-color: white;
}

.content-area .content.top {
  border-radius: 0 0 0.5rem 0.5rem;
}

.content-area .content .label {
  text-decoration: underline;
  text-decoration-color: var(--btc-orange);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.content-area .content .bottom {
  text-align: center;
}
