.currency-drop-area {
  width: 100%;
}

.currency-drop-area .content {
  max-width: 994px;
  padding: 1rem;
  margin: 0 auto;
  display: block;
  overflow: auto;
  border-left: 0.1rem solid var(--btc-orange);
  border-right: 0.1rem solid var(--btc-orange);
}
