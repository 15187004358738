:root {
  --btc-orange: #f2a900;
  --btc-orange-middle: #f2a900c0;
  --btc-orange-soft: #f2a90070;
  --btc-orange-light: #f2a90016;
  --toastify-color-progress-light: var(--btc-orange);
  --toastify-color-progress-dark: var(--btc-orange);
}


.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}